<template>
    <div id="feedback">
        <h1 class="title"> Thank You! </h1>
        <p>
            Your payment was successful.
            <span v-if="!isBusinessUnitMegalytic()">
                Get 20% off by converting your monthly subscription to Yearly anytime.
            </span>
        </p>
        

        <br>

        <span>Looking for a connector? </span>
        <a :href="`${getFrontEndSkinDataSourceInterestForm()}`"> Let us Know </a>

        <br>

        <span> Have any questions? </span>
        <a :href="`mailto:${getFrontEndSkinSupportEmail()}`">
            Contact us
        </a>

        <br>
        <br>
        <div v-if="displayFeedbackForm">
        <h2 class="subtitle"> How did you hear about {{ getFrontEndSkinName() }}? </h2>
        <div class="text-area-input">
            <b-input
            v-model="input.feedback"
            :maxlength="maxLength.feedback"
            rows="4"
            type="textarea" />
        </div>
        <br>
        <span @mouseover="isFeedbackValid">
            <b-button
              :disabled="input.feedback.length === 0"
              class="rounded-button"
              label="Submit"
              type="is-primary"
              @click="submitFeedback"
               />
        </span>
        </div>
        <br>
        <br>
        <footer>
            <a href="https://datastudio.google.com/"> Go to Data Studio </a>
            <a href="/data-sources"> Go to Homepage </a>
        </footer>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BillingAPI from '../api/modules/Billing';

    export default {
        name: 'FeedbackModal',
        data() {
            return {
                displayFeedbackForm: true,
                input: {
                    feedback: ''
                },
                maxLength: {
                    feedback: 500
                },
            }
        },
        computed: {
            isFeedbackEmpty() {
                return (this.input.feedback.length === 0);
            },
        },
        mounted() {
            const gTagId = this.getFrontEndSkinGTagId();
            /* eslint-disable */
            (function() {
                const gs = document.createElement('script');
                gs.src = `https://www.googletagmanager.com/gtag/js?id=${gTagId}`;
                gs.type = 'text/javascript';
                gs.async = 'true';
                gs.onload = gs.onreadystatechange = function() {
                    const rs = this.readyState;
                    if (rs && rs != 'complete' && rs != 'loaded') return;
                    try {
                        function gtag(...args){
                            dataLayer.push(args);
                        }
                        gtag('js', new Date());
                        gtag('config', gTagId);
                    } catch (e) {
                        console.log("ERROR: Error linking GTag scripts");
                        console.error(e);
                    }
                };
                const s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(gs, s);
            })();
        },
        created() {
            this.displayFeedbackForm = !this.isFeedbackSubmitted();
        },
        methods: {
            isFeedbackValid() {
                let message;
                if(this.isFeedbackEmpty) {
                    message = 'Feedback form is empty, Please enter before submitting.';
                } else if (this.input.feedback.length > this.maxLength.feedback) {
                    message = 'Please do not exceed the character limit of the feedback input';
                }
                if (message) {
                    this.$buefy.toast.open({
                        message,
                        type: 'is-warning',
                        duration: 4000
                    });
                    return false;
                }
                return true;
            },
            submitFeedback() {
                if (!this.isFeedbackValid()) return;
                if (!this.isFeedbackEmpty) {
                    this.displayFeedbackForm = false;
                    const errorToastOptions = {
                        message: 'There is an error while submitting feedback. Please try again or contact support.',
                        type: 'is-danger',
                    };
                    BillingAPI.feedback(this.input)
                    .then((response) => {
                        if (response.data.status === 'Success') {
                            this.$buefy.toast.open({
                                message: 'Your response have been recorded with us. Thank you!',
                                type: 'is-success',
                            });
                        } else {
                            this.displayFeedbackForm = true;
                            this.$buefy.toast.open(errorToastOptions);
                        }
                    }).catch(() => {
                        this.displayFeedbackForm = true;
                        this.$buefy.toast.open(errorToastOptions);
                    });
                }
            },
            ...mapGetters('billing', ['isFeedbackSubmitted']),
        },
    }
</script>

<style>
    #feedback {
        margin: 20px;
        padding: 10px;
    }
    footer {
        display: flex;
        justify-content: space-between;
    }
</style>


